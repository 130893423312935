<template>
    <div>
        <div v-if="roomInfo && roomInfo.name" class="d-flex justify-content-between">
            <div style="font-weight: 900; font-size: 1.5rem;">
                {{ roomInfo.name.replaceAll("_", " ") }} - {{ dayjs(roomInfo.start_time).format('D.M.YYYY') }}
            </div>
            <div class="d-flex align-items-center" style="font-size: 1.2rem;">
                <div class="circle mr-1"></div> v živo
            </div>
        </div>

        <div class="row p-1 my-1" style="font-weight: 900; font-size: 1.3rem; background: #bdd9ef;">
               
        </div>

        <div class="d-flex justify-content-center mx-2" v-if="roomInfo && jitsiOptions && jitsiOptions.jwt">
            <vue-jitsi-meet
                ref="jitsiRef"
                :domain="jitsiDomain"
                :options="jitsiOptions"
            ></vue-jitsi-meet>
        </div>
        <div class="mt-3 text-center" style="font-size: 25px;" v-else>
            Sestanek neobstaja ali nimate pravic za dostop.
        </div>

        <!-- <b-row class="mt-4" align-v="center" >
            <b-col cols="12" sm="6" class="d-flex justify-content-between">
                <b-button squared variant="primary" class="ml-2">
                    <h4 class="mb-0 text-white">Prenesi zapisnik <fa icon="download"/></h4>
                </b-button>
            </b-col>
            <b-col cols="12" sm="6" class="d-flex justify-content-end mt-1 mt-sm-0">
                <b-button squared variant="primary" class="mr-2">
                    <h4 class="mb-0 text-white">Potrdi prisotnost <fa icon="check-circle"/></h4>
                </b-button>
            </b-col>
        </b-row> -->
    </div>
    
</template>

<script>
    import { JitsiMeet } from '@mycure/vue-jitsi-meet'
    // import {BRow, BCol, BButton} from 'bootstrap-vue'

    export default {
        components:{
            VueJitsiMeet: JitsiMeet
            // BRow,
            // BCol,
            // BButton
        },
        data() {
            return {
                jitsiDomain: process.env.VUE_APP_JITSI_URL,
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJCRUU2MiIsImNvbnRleHQiOnsidXNlciI6eyJlbWFpbCI6ImFkbWluQGl0LXRpbS5zaSIsImlkIjoiNjBjYzk4NDE1ZjJhYmRlYWM3MmI3OTUyUWxlUVloWXpSeSIsIm5hbWUiOiJBZG1pbiBBZG1pbiBRbGVRWWhZelJ5In19LCJpYXQiOiIyMDIyLTAyLTE0VDIwOjQ4OjQ2LjcyOTQwODgrMDE6MDAiLCJpc3MiOiJCRUU2MiIsInJvb20iOiJkZW1vIiwic3ViIjoibWVldC5qaXQuc2kiLCJ2YWxpZFVudGlsIjoiMjAyMi0wMi0xNFQyMDo0ODo0Ni43Mjk0MDg4KzAxOjAwIn0.wKNGx7BtUnz7qFId8a2ZrOhHgXZvaeN_wU3zoSVtDd0',
                jitsiOptions: {
                    roomName: null,
                    jwt: null,
                    height: 500
                },
                roomInfo: null
            }
        },
        async mounted() {
            const roomId = this.$route.params.room_id
        
            const data = await this.$http.get(`/api/user/v1/jitsi/getMeetingToken?room_id=${  roomId}`)

            this.jitsiOptions.jwt = data.data.token
            this.jitsiOptions.roomName = data.data.room.name

            this.roomInfo = data.data.room
        }
    }
</script>

<style lang="scss" scoped>
    .circle{
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        background: red;
    }
</style>